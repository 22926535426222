<template>
  <div class="deleteWrap">
    <el-dialog
      width="400px"
      :visible.sync="dialogVisible"
      center
      :show-close="false"
    >
      <div class="textWrap">
        <p>确认删除任务吗？</p>
      </div>
      <div class="btnWrap">
        <el-button type="info" round @click="dialogVisible = false"
          >取消</el-button
        >
        <el-button type="primary" round @click="submit">删除</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { taskDel } from "../../assets/api";
export default {
  data() {
    return {
      dialogVisible: false,
      task_id: "",
    };
  },
  mounted() {},
  methods: {
    openInit(item) {
      this.dialogVisible = true;
      this.task_id = item.id;
    },
    submit() {
      const params = {
        uid: JSON.parse(sessionStorage.getItem("infos")).uid,
        task_id: this.task_id,
      };
      taskDel(params).then((res) => {
        if (res.code == 0) {
          this.$parent.getList(1);
          this.dialogVisible = false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.deleteWrap {
  /deep/.el-dialog {
    border-radius: 16px;
    .el-dialog__header{
      display:none;
    }
    .el-dialog__body{
      padding-top:50px;
    }
  }
  .textWrap {
    text-align: center;
    margin-bottom: 50px;
    font-size: 14px;
    color: #777;
    p:nth-child(1) {
      font-size: 18px;
      margin-bottom: 12px;
      color: #333;
    }
  }
  .btnWrap {
    text-align: center;
    .el-button.is-round {
      padding: 12px 45px;
    }
    .el-button--info {
      background: #eeeeee;
      border: 1px solid #eeeeee;
      color: #666;
      &:hover,
      &:focus {
        background: #ddd;
      }
    }
  }
}
</style>
