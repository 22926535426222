<template>
	<div class="taskWrap">
		<div class="titleWrap">
			<div>
				<ul class="taskTab">
					<li
						v-for="(item, index) in taskTab"
						:key="index"
						:class="nowActive == item.id ? 'on' : ''"
						@click="changeTab(item)"
					>
						{{ item.name }}
					</li>
				</ul>
			</div>
			<el-button  plain round @click="addTask" class="bluePlainBtn"
				><i class="el-icon-circle-plus-outline"></i> 新建任务</el-button
			>
		</div>
		<div class="noDataWrap" v-if="taskList.length == 0">
			<img src="@/assets/images/noData.png" alt="" />
			<p v-if="nowActive == 2">暂无学习任务</p>
			<p v-if="nowActive == 0">没有未完成的任务，快去新建任务吧</p>
			<p v-if="nowActive == 1">没有完成的任务，快去完成任务吧</p>
		</div>
		<div v-else>
			<div
				class="itemWrap"
				v-for="(item, index) in taskList"
				:key="index"
			>
				<img
					src="../assets/images/finishTask.png"
					alt=""
					v-if="item.status == 1"
				/>
				<img
					src="../assets/images/unfinishTask.png"
					alt=""
					v-if="item.status == 0"
				/>
				<div class="contentWrap">
					<p class="title">{{ item.title }}</p>
					<p class="content">{{ item.content }}</p>
					<div class="timeWrap">
						<p>
							<i class="iconfont icon-time"></i
							>{{
								item.start_time | formatimes('yyyy-MM-dd hh:mm')
							}}
							~
							{{ item.end_time | formatimes('yyyy-MM-dd hh:mm') }}
						</p>
						<div>
							<span
								class="colorRed taskdel"
								@click="deleteTask(item)"
								>删除</span
							>
							<span
								v-if="item.status == 0"
								class="colorBlue taskedit"
								@click="editAdd(item)"
								>编辑</span
							>
							<el-button
								type="info"
								round
								v-if="item.status == 0"
								@click="finishTask(item)"
								>完成</el-button
							>
						</div>
					</div>
				</div>
			</div>
			<p class="moreBtn" @click="getMore">
				<i class="iconfont icon-more_btn mr5"></i>{{ btnMessage }}
			</p>
		</div>
		<TaskAdd ref="TaskAdd"></TaskAdd>
		<TaskDelete ref="TaskDelete"></TaskDelete>
		<TaskFinish ref="TaskFinish"></TaskFinish>
	</div>
</template>

<script>
import { taskList } from '../assets/api';
import TaskAdd from './com/taskAdd.vue';
import TaskDelete from './com/taskDelete.vue';
import TaskFinish from './com/taskFinish.vue';
export default {
	components: {
		TaskAdd,
		TaskDelete,
		TaskFinish,
	},
	data() {
		return {
			taskList: [],
			page: 1,
			limit: 20,
			btnMessage: '加载更多',
			taskTab: [
				{
					id: 2,
					name: '全部',
				},
				{
					id: 0,
					name: '未完成',
				},
				{
					id: 1,
					name: '已完成',
				},
			],
			nowActive: 2,
		};
	},
	mounted() {
		this.getList(1);
	},
	methods: {
		getList(type) {
			if (type == 1) {
				this.page = 1;
				this.taskList = [];
			}
			const params = {
				uid: JSON.parse(sessionStorage.getItem('infos')).uid,
				limit: this.limit,
				page: this.page,
				status: this.nowActive,
			};
			taskList(params).then((res) => {
				if (res.code == 0) {
					if (res.data) {
						if (type == 1) {
							this.taskList = res.data;
						}
						if (type == 2) {
							this.taskList = this.taskList.concat(res.data);
						}
					} else {
						this.btnMessage = '暂无更多';
					}
				} else {
					this.page--;
				}
			});
		},
		changeTab(item) {
			this.nowActive = item.id;
			this.taskList = [];
			this.limit = 20;
			this.page = 1;
			this.btnMessage = '加载更多';
			this.getList(1);
		},
		addTask() {
			this.$refs.TaskAdd.openInit(1);
		},
		deleteTask(item) {
			this.$refs.TaskDelete.openInit(item);
		},
		finishTask(item) {
			this.$refs.TaskFinish.openInit(item);
		},
		editAdd(item) {
			this.$refs.TaskAdd.openInit(2, item);
		},
		getMore() {
			if (this.btnMessage == '加载更多') {
				this.page++;
				this.getList(2);
			}
		},
	},
};
</script>

<style scoped lang="scss">
.taskWrap {
	padding: 20px 30px;
	box-sizing: border-box;
	.noDataWrap {
		text-align: center;
		margin: 100px 113px;
		p {
			margin: 25px 0;
			font-size: 16px;
			color: #999999;
		}
	}
	.titleWrap {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.taskTab {
			display: flex;
			li {
				margin-right: 40px;
				font-size: 16px;
				cursor: pointer;
				position: relative;
				&.on {
					color: var(--change-color);
					font-weight: bold;
				}
				&.on::after {
					clear: both;
					content: '';
					position: absolute;
					left: 50%;
					bottom: -9px;
					width: 24px;
					height: 3px;
					margin-left: -12px;
					background: var(--change-color);
					border-radius: 1.5px;
				}
			}
		}

		i {
			font-size: 16px;
		}
		.el-button.is-round {
			//padding: 10px 20px;
      width: 118px;
      height: 36px;
      padding:10px 23px
		}
    .bluePlainBtn{
      background:#fff;
      border:1px solid var(--change-color);
      color:var(--change-color);
      i{}
      &:hover{
        background:var(--change-color);
        color:#fff
      }
    }
	}
	.itemWrap {
		padding: 30px 0 0;
		display: flex;
		img {
			width: 88px;
			height: 88px;
			border-radius: 6px;
			margin-right: 20px;
		}
		.contentWrap {
			width: calc(100% - 108px);
			padding-bottom: 30px;
			border-bottom: 1px solid #e9eaeb;
			font-size: 14px;
			color: #666666;
			.title {
				font-size: 16px;
				margin-bottom: 15px;
				color: #333;
			}
			.content {
				margin-bottom: 15px;
				line-height: 26px;
			}
			.timeWrap {
				display: flex;
				align-items: center;
				justify-content: space-between;
				color: #999;
				i {
					margin-right: 5px;
				}
				.colorRed {
					color: red;
					margin-right: 25px;
					cursor: pointer;
				}
				.colorBlue {
					color: var(--default-color);
					margin-right: 25px;
					cursor: pointer;
				}
				.el-button.is-round {
					padding: 5px 15px;
				}
				.el-button--info {
					background: #f2f2f2;
					border: 1px solid #f2f2f2;
					color: #333;
				}
			}
		}
		.taskdel,
		.taskedit {
			display: none;
		}
		&:hover {
			.taskdel,
			.taskedit {
				display: inline-block;
			}
		}
	}
	.pageWrap {
		margin-top: 20px;
		float: right;
		padding-bottom: 20px;
		display: flex;
		justify-content: flex-end;
	}
}
.moreBtn {
	margin: 0 auto;
	margin-top: 30px;
	width: 100px;
	height: 30px;
	font-size: 14px;
	border-radius: 18px;
	border: 1px solid #e1e1e1;
	text-align: center;
	line-height: 30px;
	cursor: pointer;
	color: #666;
	&:hover {
		background: var(--change-color);
		border: 1px solid var(--change-color);
		color: #fff;
	}
	i {
		font-size: 14px;
	}
}
</style>
