<template>
  <div class="addWrap">
    <el-dialog
      width="700px"
      :title="type == 1 ? '新建任务' : '编辑任务'"
      :visible.sync="dialogVisible"
      custom-class="taskDia"
    >
      <div class="contentWrap">
        <el-row class="mb20">
          <el-col :span="4"><span class="colorRed mr5">*</span>任务名称</el-col>
          <el-col :span="20">
            <el-input
              placeholder="请输入任务名称，至多30字符"
              maxlength="30"
              v-model.trim="form.title"
            ></el-input>
          </el-col>
        </el-row>
        <el-row class="mb20">
          <el-col :span="4"><span class="colorRed mr5">*</span>任务内容</el-col>
          <el-col :span="20">
            <el-input
              placeholder="请输入任务内容，至多200字符"
              maxlength="200"
              type="textarea"
              :rows="4"
              v-model="form.content"
            ></el-input>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4"><span class="colorRed mr5">*</span>任务时间</el-col>
          <el-col :span="20">
            <el-date-picker
              v-model="form.time"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="timestamp"
            >
            </el-date-picker>
          </el-col>
        </el-row>
      </div>
      <div class="btnWrap">
        <el-button
          type="primary"
          round
          @click="submit"
          :disabled="
            !form.title || !form.content || (!form.time || !form.time.length)
          "
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { taskAdd, taskEdit } from "../../assets/api";
export default {
  data() {
    return {
      dialogVisible: false,
      type: "",
      form: {
        title: "",
        content: "",
        time: [],
      },
      task_id: "",
    };
  },
  mounted() {},
  methods: {
    openInit(type, item) {
      this.dialogVisible = true;
      this.type = "";
      this.$data.form = this.$options.data().form;
      this.type = type;
      if (type == 2) {
        this.task_id = item.id;
        this.form = {
          title: item.title,
          content: item.content,
          time: [item.start_time * 1000, item.end_time * 1000],
        };
      }
    },
    submit() {
      if (this.form.title == "") {
        this.$message.error("请填写任务名称");
      } else if (this.form.content == "") {
        this.$message.error("请填写任务内容");
      } else if (this.form.time == null || this.form.time.length == 0) {
        this.$message.error("请选择任务时间");
      } else {
        if (this.type == 1) {
          const params = {
            uid: JSON.parse(sessionStorage.getItem("infos")).uid,
            title: this.form.title,
            content: this.form.content,
            start_time: this.form.time[0] / 1000,
            end_time: this.form.time[1] / 1000,
          };
          console.log(params);
          taskAdd(params).then((res) => {
            if (res.code == 0) {
              this.$parent.getList(1);
              this.dialogVisible = false;
            }
          });
        }
        if (this.type == 2) {
          const params = {
            uid: JSON.parse(sessionStorage.getItem("infos")).uid,
            task_id: this.task_id,
            title: this.form.title,
            content: this.form.content,
            start_time: this.form.time[0] / 1000,
            end_time: this.form.time[1] / 1000,
          };
          taskEdit(params).then((res) => {
            if (res.code == 0) {
              this.$parent.getList(1);
              this.dialogVisible = false;
            }
          });
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.addWrap {
  /deep/.el-dialog {
    border-radius: 16px;
    .el-dialog__header {
      padding: 20px;
      .el-dialog__title {
        font-weight: bold;
        font-size: 16px;
      }
      i {
        font-size: 20px;
        // font-weight:bold
      }
    }
    .el-dialog__body {
      padding: 15px 30px 40px;
    }
  }
  .btnWrap {
    text-align: center;
    margin-top: 30px;
    .el-button.is-round {
      padding: 12px 45px;
      border:none;
    }
  }
  .contentWrap {
    font-size: 16px;
    line-height: 48px;
    color: #333;
    .colorRed {
      color: red;
    }
    /deep/.el-input__inner {
      height: 48px;
      line-height: 48px;
      border: none;
      background: #f8f8f8;
      font-size: 16px;
      border-radius: 6px;
      width: 100%;
      color: #333;
    }
    /deep/.el-textarea__inner {
      border: none;
      background: #f8f8f8;
      font-size: 16px;
      border-radius: 6px;
      padding: 14px;
      color: #333;
    }
    /deep/.el-date-editor .el-range-separator {
      line-height: 48px;
      color: #333;
    }
    /deep/.el-range-editor.el-input__inner {
      padding: 0 15px;
      color: #333;
    }
    /deep/.el-date-editor .el-range-input {
      color: #333;
    }
  }
}

</style>
